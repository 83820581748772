const opciones = {
    es: [
        {
            titulo: "Servicios IoT",
            subtitulo: "Servicios",
            subtitulo2: {
                titulo: "Red Mesh para tus dispositivos IoT",
                path: "/servicios/red_mesh_para_tus_dispositivos_IoT"
            },
            opciones: [
                {
                    titulo: "Medidores IoT WASION - SOFTEL",
                    path: "/servicios/medidores_iot_wasion_softel",
                    icon: require("../assets/img/Iconos Navegación/WasionSoftel.png"),
                },
                {
                    titulo: "Comunicación Mesh y Celular para Medidores Eléctricos",
                    path: "/servicios/comunicacion_mesh_y_celular_para_medidores_electricos",
                    icon: require("../assets/img/Iconos Navegación/ComunicacionMedidores.png")

                },
                {
                    titulo: "Comunicación Mesh y Celular para Luminarias Públicas",
                    path: "/servicios/comunicacion_mesh_y_celular_para_luminarioas_publicas",
                    icon: require("../assets/img/Iconos Navegación/ComunicacionLuminarias.png")
                },
                {
                    titulo:  "Monitoreo de Productos Refrigerados",
                    path:  "/servicios/monitoreo_de_productos_refrigerados",
                    icon: require("../assets/img/Iconos Navegación/ProductosRefrigerados.png")
                },
                {
                    titulo: "Monitoreo de Activos Indoor",
                    path: "/servicios/monitoreo_de_activos_indoor",
                    icon: require("../assets/img/Iconos Navegación/ActivosIndoor.png"),
                },
                {
                    titulo: "Enlaces monitoreados de Comunicación Vía Celular",
                    path: "/servicios/enlaces_monitoreados_de_comunicacion_via_celular",
                    icon: require("../assets/img/Iconos Navegación/ComunicacionCelular.png")
                },
                {
                    titulo: "Monitoreo de Entorno ATMs",
                    path: "/servicios/monitore_entornos_atms",
                    icon: require("../assets/img/Iconos Navegación/EntornoAtms.png")
                },
                // {
                //     titulo: "SIMs y Planes de Datos",
                //     path: "/servicios/planes_datos",
                //     icon: require("../assets/img/Iconos Navegación/SimsPlanesDatos.png")
                // },
                {
                    titulo: "Puntos Acceso WIFI",
                    path: "/servicios/puntos_acceso_wifi",
                    icon: require("../assets/img/Iconos Navegación/pwifi_menu.png"),
                }
            ]            
        },
        {
            titulo: "Productos IoT",
            subtitulo: "Productos",
            subtitulo2: undefined,
            opciones: [
                {
                    titulo: "Servicios de Diseño",
                    path: "/productos/servicios_diseño",
                    icon: require("../assets/img/Iconos Navegación/ServiciosDiseño.png")
                },
                
            ]            
        },
        {
            titulo: "Refrigeración IoT",
            subtitulo: "",
            subtitulo2: undefined,
            opciones: [
                {
                    titulo: "Control Refrigeración EASYCOOL® ",
                    path:  "/contacto",
                    icon: require("../assets/img/Iconos Navegación/EasyCool.png")
                },
                {
                    titulo: "Controles de refrigeración IMBERA ",
                    path: "/productos/controles_refrigeracion_IMBERA",
                    icon: require("../assets/img/Iconos Navegación/RefrigeracionIMBERA.png")
                },
                
            ]            
        },
        {
            titulo: "Contacto",
            subtitulo: "",
            subtitulo2: undefined,
            opciones: [],
            path: "/contactanos"
        },
        {
            titulo: "Nosotros",
            subtitulo: "",
            subtitulo2: undefined,
            opciones: [
                {
                    titulo: "Certificaciones",
                    path: "/nosotros/certificaciones",
                    icon:  require("../assets/img/Certificaciones.png")
                },
                {
                    titulo: "Políticas",
                    path: "/nosotros/politicas",
                    icon: require("../assets/img/Politicas.png")
                }
            ]            
        },
        {
            titulo: "Tienda en línea",
            subtitulo: "",
            subtitulo2: undefined,
            opciones: [],
            path: undefined,
            link: "https://softel.odoo.com/shop"
        },
        {
            titulo: "ES",
            subtitulo: "Cambiar idioma",
            subtitulo2: undefined,
            id: "language",
            opciones: [
                {
                    titulo: "Español - ES",
                   value: "es",
                   path: ""
                },
                {
                    titulo: "Ingles - EN",
                    value: "en",
                    path: ""
                }
            ]            
        }
    ], 
    en: [
        {
            titulo: "IoT Services",
            subtitulo: "Services",
            subtitulo2: {
                titulo: "Mesh network for your IoT devices",
                path: "/servicios/red_mesh_para_tus_dispositivos_IoT"
            },
            opciones: [
                {
                    titulo: "WASION-SOFTEL IoT Meters",
                    path: "/servicios/medidores_iot_wasion_softel",
                    icon: require("../assets/img/Iconos Navegación/WasionSoftel.png"),
                },
                {
                    titulo: "Mesh and Cellular Communication for Electric Meters",
                    path: "/servicios/comunicacion_mesh_y_celular_para_medidores_electricos",
                    icon: require("../assets/img/Iconos Navegación/ComunicacionMedidores.png")

                },
                {
                    titulo: "Mesh and Cellular Communication for Public Lighting",
                    path: "/servicios/comunicacion_mesh_y_celular_para_luminarioas_publicas",
                    icon: require("../assets/img/Iconos Navegación/ComunicacionLuminarias.png")
                },
                {
                    titulo: "Monitoring of Refrigerated Products",
                    path:  "/servicios/monitoreo_de_productos_refrigerados",
                    icon: require("../assets/img/Iconos Navegación/ProductosRefrigerados.png")
                },
                {
                    titulo: "Indoor Asset Monitoring",
                    path: "/servicios/monitoreo_de_activos_indoor",
                    icon: require("../assets/img/Iconos Navegación/ActivosIndoor.png"),
                },
                {
                    titulo: "Monitored Cellular Communication Connections",
                    path: "/servicios/enlaces_monitoreados_de_comunicacion_via_celular",
                    icon: require("../assets/img/Iconos Navegación/ComunicacionCelular.png")
                },
                {
                    titulo: "ATM Environment Monitoring",
                    path: "/servicios/monitore_entornos_atms",
                    icon: require("../assets/img/Iconos Navegación/EntornoAtms.png")
                },
                // {
                //     titulo: "SIM Cards and Data plans",
                //     path: "/servicios/planes_datos",
                //     icon: require("../assets/img/Iconos Navegación/SimsPlanesDatos.png")
                // },
                {
                    titulo: "WIFI Access Points",
                    path: "/servicios/puntos_acceso_wifi",
                    icon: require("../assets/img/Iconos Navegación/pwifi_menu.png"),
                }
            ]            
        },
        {
            titulo: "IoT Products",
            subtitulo: "Productos",
            subtitulo2: undefined,
            opciones: [
                {
                    titulo: "Design Services",
                    path: "/productos/servicios_diseño",
                    icon: require("../assets/img/Iconos Navegación/ServiciosDiseño.png")
                },
                
            ]            
        },
        {
            titulo: "IoT Refrigeration",
            subtitulo: "",
            subtitulo2: undefined,
            opciones: [
                {
                    titulo: "EASYCOOL®  Refrigeration Control",
                    path:  "/contacto",
                    icon: require("../assets/img/Iconos Navegación/EasyCool.png")
                },
                {
                    titulo: "IMBERA Refrigeration Controls",
                    path: "/productos/controles_refrigeracion_IMBERA",
                    icon: require("../assets/img/Iconos Navegación/RefrigeracionIMBERA.png")
                },
                
            ]            
        },
        {
            titulo: "Contact",
            subtitulo: "",
            subtitulo2: undefined,
            opciones: [],
            path: "/contactanos"        
        },
        {
            titulo: "Us",
            subtitulo: "",
            subtitulo2: undefined,
            opciones: [
                {
                    titulo: "Certifications",
                    path: "/nosotros/certificaciones",
                    icon:  require("../assets/img/Certificaciones.png")
                },
                {
                    titulo: "Policies",
                    path: "/nosotros/politicas",
                    icon: require("../assets/img/Politicas.png")
                }
            ]            
        },
        {
            titulo: "Ecommerce",
            subtitulo: "",
            subtitulo2: undefined,
            opciones: [],
            path: undefined,
            link: "https://softel.odoo.com/shop"
        },
        {
            titulo: "EN",
            subtitulo: "Change language",
            subtitulo2: undefined,
            id: "language",
            opciones: [
                {
                    titulo: "Spanish - ES",
                    value: "es",
                    path: ""
                },
                {
                    titulo: "English - EN",
                    value: "en",
                    path: ""
                }
            ]            
        }
    ]
};


export default opciones;