import React, { useEffect, useState, useContext } from "react";
import "assets/css/panelHeaderResponsve-v2.css";
//import optionMenu from "variables/menu";
import optionMenu from "variables/opciones_menu";
import { Icon } from "@iconify/react";
import iconDown from "@iconify/icons-ic/baseline-arrow-drop-down";
import iconUp from "@iconify/icons-ic/baseline-arrow-drop-up";
import world from "@iconify/icons-clarity/world-outline-badged";
import { NavLink } from "react-router-dom";
import LanguageContext from "context/context-language";
import { useLocation } from "react-router-dom";

function panelHeaderResponsive({ isOpen, active, open }) {
  //const [ isOpen, setIsOpen ] = useState(false);
  const [option, setOption] = useState(null);
  const { language, handleLanguage } = useContext(LanguageContext);
  let menu = optionMenu[language];

  const { pathname } = useLocation();
  let split = pathname.split("/")[2];

  useEffect(() => {
    // console.log(language);
  }, [split]);

  const changeOption = (id) => {    
    if (id === option) {
      setOption(null);
    } else {
      setOption(id);
    }
    //isOpen(false);
  };

  return (
    <>
      <div className={`sidebar ${open === true ? "sidebar-open" : ""}`}>
        <div 
          className="container-optiones"
          style={{                 
          opacity: open === true ? 1 : 0
        }}>
          <ul>
            <NavLink to={"/"} style={{ color: split === undefined ? "#004A88" : "", textDecoration: 'none', fontWeight: 'bold', color: '#004A88' }} onClick={() => isOpen()}>
                <p>{language === 'es' ? 'Inicio': 'Home'}</p>
              </NavLink>
            
            {
              menu.map((item, index) => {
                return(
                  <>
                    <li>
                      <input type="radio" id={"opt_" + index} name="sidebar" className="inputOpt"/>
                      <label for={"opt_" + index}>
                        
                        <div style={{display: 'flex', alignItems: 'center'}}>
                          <p>
                          {/* {item.titulo}  */}
                          {
                            item.path
                            ? <NavLink to={item.path} onClick={() => isOpen()}>
                                {item.titulo}
                              </NavLink>
                            :
                              item.link
                              ? <a href={item.link} target="_blanck" aria-label={item.titulo} rel="noreferrer">{item.titulo}</a>
                              :item.titulo
                          }
                          {item.opciones.length > 0 && <Icon icon={option === index ? iconUp : iconDown} />}
                          </p>
                         
                        </div>
                      </label>
                      {
                        item.opciones.length > 0 &&
                        <div class="sub-menu">
                          <ul>
                            {
                              item.opciones.map((submenu, i) => {
                                return(                                 
                                  <>
                                    {
                                    item.id !== 'language'
                                    ?
                                      <>
                                          <NavLink to={submenu.path} onClick={() => isOpen()} style={{margin: '2px'}}>
                                          <div className="submenuaa" style={{fontSize: '13px', display: 'flex', alignItems: 'center'}}>
                                            <div style={{marginRight: '5px'}}>
                                              <img src={submenu.icon}/>
                                            </div>
                                             <div style={{whiteSpace: 'break-spaces'}}>
                                              {submenu.titulo} 
                                             </div>
                                          </div>                                 
                                        </NavLink>
                                      </>
                                    : 
                                      <div style={{ fontSize: "12px", marginRight: "10px" }}>
                                        <input
                                          type="radio"
                                          value={submenu.value}
                                          name="gender"
                                          checked={language === submenu.value}
                                          onChange={() => {
                                            handleLanguage(submenu.value);
                                            isOpen(false);
                                          }}
                                        />{" "}
                                        {submenu.titulo}
                                      </div>
                                  }
                                  </>
                                )
                              })
                            }
                          </ul>
                        </div>
                      }
                    </li>
                  </>
                )
              })
            }
            <div style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center'
            }}>
              <a href="http://wa.me/+525580453683" target={"_blank"} rel="noreferrer" aria-label="whatsapp">
                <div className="icon-w"></div>
              </a>
            </div>
          </ul>
        </div>
        
      </div>
    </>
  );
}

export default panelHeaderResponsive;
