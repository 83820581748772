import React, { useContext, useEffect } from "react";
import ContactoContext from "context/context-contacto";
import "assets/css/control_imbera.css";
import Contacto from "components/contacto/contacto";
import { NavLink } from "react-router-dom";
import Footer from "components/Footer/Footer1";
import LanguageContext from "context/context-language";

const Control_refrigeracion_imbera = () => {
    const { openModal, HandleContacto } = useContext(ContactoContext);
    const { language, handleLanguage } = useContext(LanguageContext);  

    let textos = {
        es:["Diseñamos y Fabricamos los controles Inteligentes de refrigeración y Displays de refrigeradores IMBERA.", "Contamos con venta directa para  refacciones.", "Mercado Libre", "Contáctanos", "Tienda en línea"],
        en:["We design and manufacture intelligent refrigeration controls and displays for IMBERA refrigerators. ", "We offer direct sales for spare parts.", "Mercado Libre", "Contac us", "Ecommerce"]
    }
    useEffect(() => {
        console.log('imbera')
    }, []);
    return(
        <>
            <div className="control_imbera">
                <div style={{position: 'relative', width: '60%'}}>
                    <div style={{position: 'relative', width: '100%', color: '#fff', fontWeight: 'bold', fontSize: '30px', textAlign: 'left', textAlign: 'center'}}>
                        {textos[language][0]}
                    </div>
                    <div style={{position: 'relative', width: '100%', color: '#fff', fontSize: '20px', textAlign: 'center', marginTop: '20px'}}>
                    {textos[language][1]}
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                        {/* <a href="https://listado.mercadolibre.com.mx/_CustId_1241321665" target={'_blank'} rel="noreferrer" style={{textDecoration: 'none'}}>
                            <div className="btn-more">{textos[language][2]}</div>                        
                        </a> */}
                        <a href="https://softel.odoo.com/shop" target={'_blank'} rel="noreferrer" style={{textDecoration: 'none'}}>
                            <div className="btn-more">{textos[language][4]}</div>                        
                        </a>
                         <a href="http://wa.me/+525580453683" target={"_blank"} rel="noreferrer" aria-label="whatsapp" style={{color: 'transparent'}}>
                            <div className="btn-contact" 
                                // onClick={() => HandleContacto(true)}
                            >{textos[language][3]}</div>
                         </a>
                        
                    </div>
                </div>                
            </div>   
            <Footer/>
            <Contacto/>         
        </>
    )
}

export default Control_refrigeracion_imbera;