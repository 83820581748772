import { Col, Row } from "reactstrap";

const detalle = {
  servicios: {
    red_mesh_para_tus_dispositivos_IoT: {
      lateral: {
        icono: require("../assets/img/servicios/servicios_iot/ServiciosIoT.png"),
        titulo: "Red Mesh para tus dispositivos IoT",
        img: [
          require("../assets/img/servicios/servicios_iot/WiSun-Alliance-logo.png"),
          require("../assets/img/servicios/servicios_iot/lte-m_logo_blanco.png"),
          require("../assets/img/servicios/servicios_iot/narrowband_logo_blanco.png"),
        ],
        descrgas: false,
      },
      contenido: {
        descripcion:
          "Nuestra red pública de telecomunicación para habilitar aplicaciones relacionadas con el Internet de las Cosas (IoT) que apoya e impulsa la Transformación Digital de la sociedad.",
        secciones: [
          {
            titulo: "Ventajas de la solución",
            subtitulo2: [
              "Contamos con tecnología propietaria certificada por ",
              "WISUN Alliance para NICs",
              " y para",
              " Border Routers.",
              " Cubrimos cada uno de los elementos de la Smart Grid.",
            ],
            icon: require("../assets/img/servicios/servicios_iot/ventajas.png"),
            points: [
              "Tarjetas de red (NIC) para diferentes mercados con excelente relación costo-beneficio",
              "Fabricación de nuestros propios border routers que reducen los costos de la red",
              "Capacidad analítica de nuestro NOC que posibilita el monitoreo en tiempo real de la calidad de servicio (QoS) de la red mediante Plataformas de Gestión y Monitoreo de Red",
              "Alta disponibilidad (Always on)",
            ],
            descripcion:
              "Equipos de red con alta disponibilidad gracias a su diseño redundante y batería de respaldo en caso de falla eléctrica",
            imagen: require("../assets/img/servicios/servicios_iot/ventajas.jpg"),
            left: true,
          },
          {
            titulo: "Características",
            subtitulo: "Nuestra red Mesh destaca en los siguientes puntos:",
            icon: require("../assets/img/servicios/servicios_iot/NET.png"),
            points: [
              "Interoperabilidad total",
              "Tiempos reducidos de implementación en zonas de nueva cobertura",
              "Alta velocidad de transmisión sin pérdidas",
              "Alta disponibilidad (Always on)",
              "Protección contra interferencias",
              "Alta seguridad",
            ],
            imagen: require("../assets/img/servicios/servicios_iot/caracteristicas.jpg"),
            left: false,
          },
          {
            titulo: "NOC",
            subtitulo:
              "SOFTEL Net se soporta en nuestro propio NOC (Network Operation Center) que opera 24/7 X 365 e ingeniería de campo para ofrecer un servicio carrier-class con contratos de nivel de servicio (SLAs) que garantizan una alta disponibilidad y continuidad para aplicaciones de misión crítica.",
            icon: require("../assets/img/servicios/servicios_iot/NOC.png"),
            points: [
              "Diagnóstico remoto y atención de fallas a diferentes niveles",
              "Atención a alarmas y gestión de datos mediante Machine Learning y Artificial Intelligence",
              "Personal de campo en toda la República Mexicana, y almacenes con refacciones y herramientas en las principales 58 ciudades del país",
              "En caso de afectaciones en la red, visitas coordinadas en sitio con personal de campo con SLA’s establecidos",
            ],
            imagen: require("../assets/img/servicios/servicios_iot/noc.jpg"),
            left: true,
          },
          {
            titulo: "Enfoque de negocio",
            subtitulo:
              "Servimos a los mercados que se benefician de la recolección remota y explotación de datos generados por diferentes tipos de equipos, dispositivos y cosas para tomar las mejores decisiones para su negocio.",
            icon: require("../assets/img/servicios/servicios_iot/enfoque_negocios.png"),
            points: ["Eléctrico (Power&Utilities)", "Refrigeración o Cadena de Frío", "Alumbrado público o Luminarias"],
            imagen: require("../assets/img/servicios/servicios_iot/enfoque.jpg"),
            left: false,
          },
        ],
      },
    },
    medidores_iot_wasion_softel: {
      lateral: {
        icono: require("../assets/img/servicios/medidores_iot_wasion_softel/WasionSoftel.png"),
        titulo: "Medidores IoT WASION - SOFTEL",
        img: [],
        descrgas: false,
      },
    },
    comunicacion_mesh_y_celular_para_medidores_electricos: {
      lateral: {
        icono: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/ComunicacionMedidores.png"),
        titulo: "Comunicación Mesh y Celular para Medidores Eléctricos",
        img: [],
        descrgas: true,
      },
      contenido: {
        //img_principal: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/connect_vid.mp4"),
        descripcion: "",
        secciones: [
          {
            titulo: "SOFTEL P&U",
            subtitulo:
              "Las soluciones IoT SOFTEL P&U habilitan la comunicación de medidores eléctricos (socket, gabinete e industrial) permitiendo realizar mediciones de consumo, llevar a cabo el corte y reconexión de usuarios de forma remota, cumpliendo con la norma GWH-0079 para tarjetas de escalamiento de medidores de energía.",
            imagen: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/P_U.jpg"),
            points: [],
            icon: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/P&U.png"),
            left: true,
          },
          {
            titulo: "SOFTEL IoT P&U Meter NIC",
            subtitulo:
              "La Tarjeta de Escalamiento es un módulo electrónico que se utiliza para comunicar los medidores residenciales (socket) a través de nuestra red SOFTEL Net entregando los datos a SINAMED CFE o cualquier otra plataforma de control y facturación. Trabaja bajo las normas actuales para comunicación de medidores eléctricos.",
            imagen: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/IoT_Meter_NIC.jpg"),
            points: [],
            icon: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/P&U_Meter_NIC.png"),
            left: false,
          },
          {
            titulo: "SOFTEL IoT P&U Cabinet Gateway ",
            subtitulo:
              "Equipo encargado de comunicar  medidores tipo gabinete a través  de nuestra red SOFTEL Net entregando los datos a SINAMED CFE o cualquier otra plataforma de control y facturación. Un solo equipo de comunicación puede transmitir información de 12 a 24 Medidores, siendo la opción más rentable para conjuntos residenciales",
            imagen: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/Iot_Meter_Cabinet.jpg"),
            points: [],
            icon: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/industrial.png"),
            left: true,
          },
          {
            titulo: "SOFTEL IoT P&U Industrial NIC",
            subtitulo:
              "Nuestro producto para medidores industriales permite la obtención de perfiles de carga completos que son enviados a SINAMED CFE o cualquier otra plataforma de control y facturación. Factura, controla y analiza los consumos de tus clientes teniendo datos detallados con la red más confiable y la mejor velocidad.",
            imagen: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/IoT_Industrial_NIC.jpg"),
            points: [],
            icon: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/P&U_Meter_NIC.png"),
            left: false,
          },
        ],
      },
      descargas: [
        {
          titulo: "Tarjeta de Escalamiento 214",
          documento: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/CNCT-ESC214-ESP.pdf"),
        },
        {
          titulo: "Tarjeta de Escalamiento 229",
          documento: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/CNCT-ESC229-ESP.pdf"),
        },
        {
          titulo: "Tarjeta Escalamiento IoT para Medidores Eléctricos",
          documento: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/CNCT-GAB-ESP.pdf"),
        },
        {
          titulo: "Tarjeta Escalamiento CAT-M / NarrowBand",
          documento: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/CNCT-CATM-ESP.pdf"),
        },
      ],
    },
    comunicacion_mesh_y_celular_para_luminarioas_publicas: {
      lateral: {
        icono: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_luminarioas_publicas/ComunicacionLuminarias.png"),
        titulo: "Comunicación Mesh y Celular para Luminarias Públicas",
        img: [],
        descrgas: true,
      },
      contenido: {
        //img_principal: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_luminarioas_publicas/lumina_vid.mp4"),
        descripcion: "",
        secciones: [
          {
            titulo: "CONTROL DE LUMINARIAS",
            subtitulo:
              "Gestión de alumbrado público para municipios, alcaldías, fraccionamientos y parques industriales. Contribuimos a la optimización del consumo energético y detección oportuna de fallas.",
            points: [],
            imagen: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_luminarioas_publicas/control.jpg"),
            icon: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_luminarioas_publicas/control_luminarias.png"),
            gif: true,
            left: true,
          },
          {
            titulo: "SOFTEL IoT NEMA 3",
            subtitulo:
              "Control de encendido y apagado de luminarias por dos vías; programación horaria y control manual individual; así como detección de alarmas en parámetros de funcionalidad.",
            points: [],
            imagen: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_luminarioas_publicas/Nema_3_M2.png"),
            icon: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_luminarioas_publicas/Nema.png"),
            left: false,
          },
          {
            titulo: "SOFTEL IoT NEMA 7",
            subtitulo:
              "Telemetría detallada que recaba información del estado de funcionamiento lo que permite ampliar el monitoreo y controlar la intensidad de luminosidad para un mayor control de consumos y generación de ahorro energético.  ",
            points: [],
            imagen: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_luminarioas_publicas/Nema_7_M.png"),
            icon: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_luminarioas_publicas/Nema.png"),
            left: true,
          },
        ],
      },
      descargas: [
        {
          titulo: "Brochure",
          documento: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_luminarioas_publicas/LUM-ESP.pdf"),
        },
      ],
    },
    monitoreo_de_productos_refrigerados: {
      lateral: {
        icono: require("../assets/img/servicios/monitoreo_de_productos_refrigerados/ProductosRefrigerados.png"),
        titulo: "Monitoreo de Productos Refrigerados",
        img: [],
        descrgas: true,
      },
      contenido: {
        //img_principal: require("../assets/img/servicios/monitoreo_de_productos_refrigerados/protect.m4v"),
        descripcion: "",
        secciones: [
          {
            titulo: "Refrigeración de Medicamentos",
            subtitulo:
              "Auxiliamos en la preservación de vacunas y fármacos en refrigeración por medio de un sistema que comprende sensores, plataformas de monitoreo y herramientas Web. Otorgamos visibilidad, en tiempo real, de variaciones de temperatura para prevenir riesgos y garantizar la calidad del medicamento en todo momento",
            points: [],
            imagen: require("../assets/img/servicios/monitoreo_de_productos_refrigerados/eq41.png"),
            icon: require("../assets/img/servicios/monitoreo_de_productos_refrigerados/Asset Tracker _ EQ 41.png"),
            left: true,
          },
          {
            titulo: "Portal de Monitoreo",
            subtitulo:
              "Contribuimos a la eficiencia operativa al consolidar registros y automatizar reportes que sustituyen el trabajo manual y facilitan la entrega de informes para un control eficaz",
            points: [],
            imagen: require("../assets/img/servicios/monitoreo_de_productos_refrigerados/portal_monitoreo.jpg"),
            icon: require("../assets/img/servicios/monitoreo_de_productos_refrigerados/Portal de Monitoreo.png"),
            left: false,
          },
        ],
      },
      descargas: [
        {
          titulo: "Brochure",
          documento: require("../assets/img/servicios/monitoreo_de_productos_refrigerados/PTC-ESP.pdf"),
        },
      ],
    },
    monitoreo_de_activos_indoor: {
      lateral: {
        icono: require("../assets/img/servicios/monitoreo_de_activos_indoor/ActivosIndoor.png"),
        titulo: "Monitoreo de Activos Indoor",
        img: [],
        descrgas: true,
      },
      contenido: {
        //img_principal: require("../assets/img/servicios/monitoreo_de_activos_indoor/finder.m4v"),
        descripcion: "",
        secciones: [
          {
            titulo: "Asset Tracking",
            subtitulo:
              "Apoyamos en la ubicación y seguimiento de activos remotos usando geolocalización WIFI, enviando alarmas en caso de cambios de ubicación e inclinaciones. Toda la información es transmitida a nuestra plataforma de monitoreo usando la red LTE.",
            points: [],
            imagen: require("../assets/img/servicios/monitoreo_de_activos_indoor/asset_tracker.png"),
            icon: require("../assets/img/servicios/monitoreo_de_activos_indoor/EQ42.png"),
            left: true,
          },
          {
            titulo: "Plataforma SOFTEL",
            subtitulo:
              "Realizamos el análisis de datos de forma ágil y eficiente en un portal de gestión que permite la ubicación de activos y la visualización de alarmas en caso de movimiento",
            points: [],
            imagen: require("../assets/img/servicios/monitoreo_de_activos_indoor/gps-01.png"),
            icon: require("../assets/img/servicios/monitoreo_de_activos_indoor/plataforma softel.png"),
            left: false,
          },
        ],
      },
      descargas: [
        { titulo: "Brochure", documento: require("../assets/img/servicios/monitoreo_de_activos_indoor/FDR-ESP.pdf") },
      ],
    },
    enlaces_monitoreados_de_comunicacion_via_celular: {
      lateral: {
        icono: require("../assets/img/servicios/enlaces_monitoreados_de_comunicacion_via_celular/ComunicacionCelular.png"),
        titulo: "Enlaces monitoreados de Comunicación Vía Celular",
        img: [],
        descrgas: true,
      },
      contenido: {
        //img_principal: require("../assets/img/servicios/enlaces_monitoreados_de_comunicacion_via_celular/softel-max.m4v"),
        descripcion: "",
        secciones: [
          {
            titulo: "Servicio de comunicación de Alta Disponibilidad",
            subtitulo:
              "Ofrecemos un servicio administrado, llave en mano, con cobertura nacional para la conectividad de puntos remotos a través de un enlace celular redundante que emplea dos redes  (Telcel, Movistar, AT&T y Altán).                 El servicio incluye el equipo enrutador (MSC-4G), la plataforma de monitoreo del enlace, mantenimiento correctivo, y mesa de ayuda 7x24.",
            points: [],
            imagen: require("../assets/img/servicios/enlaces_monitoreados_de_comunicacion_via_celular/comunicacion.jpg"),
            icon: require("../assets/img/servicios/enlaces_monitoreados_de_comunicacion_via_celular/señal.png"),
            left: true,
          },
          {
            titulo: "SOFTEL Border Router",
            subtitulo:
              "Enrutador fabricado por SOFTEL, que puede emplear cualquier protocolo de comunicación para el monitoreo de variables físicas y lógicas. La comunicación se realiza mediante redes celulares con redundancia por lo que se asegura una disponibilidad de 99.5%. Su instalación se realiza en un máximo de 72 horas sin necesidad de llevar a cabo adecuaciones en el sitio ni cableados.",
            points: [],
            imagen: require("../assets/img/servicios/enlaces_monitoreados_de_comunicacion_via_celular/router.jpg"),
            icon: require("../assets/img/servicios/enlaces_monitoreados_de_comunicacion_via_celular/router.png"),
            left: false,
          },
          {
            titulo: "SOFTEL Monitoring Platform",
            subtitulo:
              "Plataforma web para visualizar en tiempo real 24/7 el estado de enlaces de comunicación considerando los siguientes datos: disponibilidad, latencia, pérdida de paquetes y tráfico consumido. Las licencias de uso se ofrecen con usuarios ilimitados.",
            points: [],
            imagen: require("../assets/img/servicios/enlaces_monitoreados_de_comunicacion_via_celular/monitoreo.jpg"),
            icon: require("../assets/img/servicios/enlaces_monitoreados_de_comunicacion_via_celular/monitoreo.png"),
            left: true,
          },
        ],
      },
      descargas: [
        {
          titulo: "Brochure",
          documento: require("../assets/img/servicios/enlaces_monitoreados_de_comunicacion_via_celular/M&G-CPR-ESP (1).pdf"),
        },
      ],
    },
    monitore_entornos_atms: {
      lateral: {
        icono: require("../assets/img/servicios/monitore_entornos_atms/EntornoAtms.png"),
        titulo: "Monitoreo de Entorno ATMs",
        img: [],
        descrgas: true,
      },
      contenido: {
        //img_principal: require("../assets/img/servicios/monitore_entornos_atms/softel_guard.m4v"),
        descripcion: "",
        secciones: [
          {
            titulo: "Control del Entorno",
            subtitulo:
              "Proporcionamos una solución que permite supervisar en tiempo real el estado de varios componentes que forman parte del entorno de un cajero automático, kiosco o sitio. Esto se logra mediante la instalación de sensores ópticos que detectan la apertura de puertas, una sonda de temperatura y un acelerómetro para identificar movimientos. También es posible incorporar cualquier otro sensor electrónico disponible en el mercado, además de contar con alarmas disuasorias en caso de detectar elementos invasivos.",
            points: [],
            imagen: require("../assets/img/servicios/monitore_entornos_atms/monitoreo_entorno.jpg"),
            icon: require("../assets/img/servicios/monitore_entornos_atms/sensor.png"),
            left: true,
          },
          {
            titulo: "SOFTEL Border Router",
            subtitulo:
              "El servicio que se ofrece implica la instalación de un equipo enrutador (MSC-4G), al cual se conectan los sensores para procesar y enviar los datos. El enrutador desarrollado por SOFTEL tiene la capacidad de realizar el direccionamiento utilizando cualquier protocolo que permita supervisar variables físicas y lógicas. Se trata de un dispositivo de comunicación independiente que cuenta con dos redes celulares y garantiza una disponibilidad del 99.5% para transmitir alarmas.",
            points: [],
            imagen: require("../assets/img/servicios/monitore_entornos_atms/router.jpg"),
            icon: require("../assets/img/servicios/monitore_entornos_atms/router.png"),
            left: false,
          },
          {
            titulo: "SOFTEL Dashboard",
            subtitulo:
              "Tablero de control web con usuarios ilimitados para visualización de alarmas en tiempo real. Informes históricos por elemento, así como capacidad de definir que alarmas se cierran en automático y cuales es necesaria intervención. Integración con sistemas de clientes vía web service",
            points: [],
            imagen: require("../assets/img/servicios/monitore_entornos_atms/dashboard.jpg"),
            icon: require("../assets/img/servicios/monitore_entornos_atms/monitoreo.png"),
            left: true,
          },
        ],
      },
      descargas: [
        { titulo: "Brochure", documento: require("../assets/img/servicios/monitore_entornos_atms/M&G-CPR-ESP.pdf") },
      ],
    },
    puntos_acceso_wifi: {
      lateral: {
        icono: require("../assets/img/servicios/puntos_acceso_wifi/pwifi_principal.png"),
        titulo: "Puntos Acceso WIFI",
        img: [],
        descrgas: false,
      },
      contenido: {
        //img_principal: require("../assets/img/servicios/monitore_entornos_atms/softel_guard.m4v"),
        descripcion: "",
        secciones: [
          {
            titulo: "Environment control",
            subtitulo:
              "We provide a solution that allows real-time monitoring of the status of various components within the environment of an ATM, kiosk, or site. This is achieved through the installation of optical sensors that detect door openings, a temperature probe, and an accelerometer for motion detection. It is also possible to incorporate any other electronic sensor available in the market, in addition to having deterrent alarms in case of detecting invasive elements.",
            points: [],
            imagen: require("../assets/img/servicios/monitore_entornos_atms/monitoreo_entorno.jpg"),
            icon: require("../assets/img/servicios/monitore_entornos_atms/sensor.png"),
            left: true,
          },
          {
            titulo: "SOFTEL Border Router",
            subtitulo:
              "The service offered involves the installation of a router device (MSC-4G), to which the sensors are connected to process and transmit the data. The router developed by SOFTEL has the capability to perform routing using any protocol that enables monitoring of physical and logical variables. It is an independent communication device that operates on two cellular networks and ensures a 99.5% availability for transmitting alarms.",
            points: [],
            imagen: require("../assets/img/servicios/monitore_entornos_atms/router.jpg"),
            icon: require("../assets/img/servicios/monitore_entornos_atms/router.png"),
            left: false,
          },
          {
            titulo: "SOFTEL Dashboard",
            subtitulo:
              "A web-based control panel with unlimited user access is provided for real-time alarm visualization. Historical reports per component are available, as well as the ability to define which alarms are automatically closed and which require intervention. Integration with client systems is possible via web services.",
            points: [],
            imagen: require("../assets/img/servicios/monitore_entornos_atms/dashboard.jpg"),
            icon: require("../assets/img/servicios/monitore_entornos_atms/monitoreo.png"),
            left: true,
          },
        ],
      },
      descargas: [],
    }
  },
  productos: {
    productos_iot: {
      lateral: {
        icono: require("../assets/img/productos/productos_iot/IoT.png"),
        titulo: "Productos IoT",
        img: [],
        descrgas: false,
      },
      contenido: {
        //img_principal: require("../assets/img/productos/productos_iot/things.m4v"),
        descripcion: "",
        secciones: [
          {
            titulo: "Diseño a la medida",
            subtitulo:
              "SOFTEL es una casa de diseño de tecnología IoT. Acompañamos a nuestros clientes durante el desarrollo y la vida útil de sus productos, adecuándolos a las necesidades del mercado. Contamos con 20 años de experiencia desarrollando dispositivos que operan con la menor intervención humana y con algoritmos que garantizan mantenerlos en línea todo el tiempo",
            points: [],
            imagen: require("../assets/img/productos/productos_iot/Diseño a la medida.jpg"),
            icon: require("../assets/img/productos/productos_iot/diseño_medida.png"),
            left: true,
          },
          {
            titulo: "Arquitecturas IoT",
            subtitulo:
              "Apoyamos a nuestros clientes a encontrar y definir la forma óptima para que sus datos fluyan desde el punto remoto hasta el centro de datos corporativo en sitio o en la nube y culminen en su almacenamiento, procesamiento y análisis. Con tecnología de punta, nuestros dispositivos toman decisiones autónomas y transmiten únicamente datos de valor a través de nuestras concentradores (Edge Computing), todo esto soportado en años de experiencia tecnológica en comunicaciones.",
            points: [],
            imagen: require("../assets/img/productos/productos_iot/Arquitecturas IoT.jpg"),
            icon: require("../assets/img/productos/productos_iot/diseño_medida.png"),
            left: false,
          },
          {
            titulo: "Integración a sistemas",
            subtitulo:
              "La infraestructura y soluciones de SOFTEL permiten a nuestros clientes conectar sensores, dispositivos, aplicativos o software de gestión, apoyándolos con API’s, Web Services, y protocolos de información que se definen para la interacción entre los diferentes sistemas. Además, garantizamos la integración con plataformas como SAP, Google, AWS y Microsoft, entre otras.",
            points: [],
            imagen: require("../assets/img/productos/productos_iot/Integración a sistemas.jpg"),
            icon: require("../assets/img/productos/productos_iot/ArqIoT.png"),
            left: true,
          },
        ],
      },
    },
    control_refrigeracion_easy_cool: {
      lateral: {
        icono: require("../assets/img/productos/control_refrigeracion_easy_cool/EasyCool.png"),
        titulo: (
          <>
            <Row>
              <Col md="12">EASYCOOL®</Col>
              <Col md="12">Control Refrigeración</Col>
            </Row>            
          </>
        ),
        img: [],
        descrgas: true,
        ecommerce: true
      },
      descargas: [
        {
          titulo: "Brochure",
          documento: require("../assets/img/productos/control_refrigeracion_easy_cool/EasyCool_Brochure_24.pdf"),
          left: true,
          text: "Descargar pdf",
        },
        { titulo: "Google Play", documento: "", left: false, text: "Descargar para Android" },
        { 
          titulo: "Manual de Usuario", 
          documento: require("../assets/img/productos/control_refrigeracion_easy_cool/EasyCool_Manual-Corto_Digital.pdf"),
          left: true, 
          text: "Descargar pdf" },
        { titulo: "App Store", documento: "", left: false, text: "Descargar para Apple" },        
      ],
      ecommerce: {
        titulo: "Tienda en línea",
        link: "https://softel.odoo.com/shop"
      }
    },
    servicios_diseño: {
      lateral: {
        icono: require("../assets/img/productos/servicios_diseño/icon_servicios_diseño.png"),
        titulo: "Servicios de Diseño",
        img: [],
        descargas: false,
      },
    },
  },
};

export default detalle;
