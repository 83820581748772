const carousel = {
  es: [
    {
      titulo: "Servicios IoT",
      descripcion: [
        "La mayor calidad de Medidores y red IoT para tu proyecto inmobiliario",
        "Con la alianza WASION - SOFTEL",
      ],
      img: require("../assets/img/carousel/WASION_Landing.png"),
      link: "/servicios/medidores_iot_wasion_softel",
      url: "../assets/img/carousel/WASION_Landing.png",
    },
    {
      titulo: "Productos IoT",
      descripcion: [
        "Consulta, configura y controla el ciclo de enfriamiento desde cualquier smartphone.",
        "Con el nuevo control para refrigeradores y congeladores",
      ],
      img: require("../assets/img/carousel/EasyCool_Landing.png"),
      // link: "/productos/control_refrigeracion_easy_cool",
      link: "/contacto",
      url: "../assets/img/carousel/control_refrigeracion_easy_cool.png",
    },
    {
      titulo: "Refrigeración IoT",
      descripcion: [
        "Asegura el óptimo funcionamiento de tus equipos de refrigeración",
        "Con  controles inteligentes de temperatura",
      ],
      img: require("../assets/img/carousel/refrigeracion.png"),
      link: undefined,
      url: "../assets/img/carousel/control_refrigeracion_easy_cool.png",
      link2: "https://softel.odoo.com/shop"
    },
  ],
  en: [
    {
      titulo: "IoT Services",
      descripcion: [
        "The Highest Quality Meters and IoT Network for Your Real Estate Project.",
        "With the WASION - SOFTEL Alliance",
      ],
      img: require("../assets/img/carousel/WASION_Landing.png"),
      link: "/servicios/medidores_iot_wasion_softel",
    },
    {
      titulo: "IoT Products",
      descripcion: [
        "Know the temperature of your products. Configure and control the cooling cycle of your refrigerator with the smartphone App. All with EASYCOOL electronic control for refrigerators and freezers.",
      ],
      img: require("../assets/img/carousel/EasyCool_Landing.png"),
      //link: "/productos/control_refrigeracion_easy_cool",
      link: "/contacto",
    },
    {
      titulo: "IoT Refrigeration",
      descripcion: [
        "Ensure the optimal performance of your refrigeration equipment",
        "With intelligent temperature controls",
      ],
      img: require("../assets/img/carousel/refrigeracion.png"),
      link: undefined,
      url: "../assets/img/carousel/refrigeracion.png",
      link2: "https://softel.odoo.com/shop"
    },
  ],
};

export default carousel;
