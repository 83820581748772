import React, { useContext, useState, useRef, createRef, useEffect } from "react";
import "assets/css/panelHeader.css";
//import optionMenu from "variables/menu";
import optionMenu from "variables/opciones_menu";
import { NavLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import iconDown from "@iconify/icons-ic/baseline-arrow-drop-down";
import iconUp from "@iconify/icons-ic/baseline-arrow-drop-up";
import world from "@iconify/icons-clarity/world-outline-badged";
import Close from "@iconify/icons-ic/close";
import Open from "@iconify/icons-ic/menu";
import PanelHeaderResponsive from "./panelHeaderResponsive";
import LanguageContext from "context/context-language";
import { useLocation } from "react-router-dom";

function PanelHeader() {
  const [isOpen, setIsOpen] = useState(false);
  const { language, handleLanguage } = useContext(LanguageContext);
  const [elemetHover, setElementHover] = useState(undefined)

  const { pathname } = useLocation();
  let split = pathname.split("/")[2];

  const [active, setActive] = useState(undefined);

  let menu = optionMenu[language];

  useEffect(() => {
    setActive(split === undefined ? "home" : split);
  }, [split]);

  return (
    <>
      <div className="panelHeader">
        <div className="panelHeader-logo">
          <NavLink to={"/"}>
            <img src={require("assets/img/logo_SOFTEL.png")} alt="logo SOFTEL" />
          </NavLink>
        </div>
        <div className="btn-menuResponsive">
          {isOpen == true ? (
            <div className="btn-openMenu" onClick={() => setIsOpen(false)}>
              <Icon icon={Close} />
            </div>
          ) : (
            <div className="btn-openMenu" onClick={() => setIsOpen(true)}>
              {" "}
              <Icon icon={Open} />
            </div>
          )}
        </div>

        <div className="panelHeader-menu">
          {
            menu.map((item, index) => {
              
              return(
                <div className="dropdown">
                  <div className={`dropbtn`} onMouseOver={() => setElementHover(index)}  onMouseOut={() => setElementHover(undefined)} id={index}>
                    {item.id === 'language' && <Icon icon={world} style={{marginRight: '5px'}}/>}
                    
                    {
                      item.path 
                      ?
                        <NavLink to={item.path}>
                          {item.titulo}
                        </NavLink>
                      :
                        item.link
                        ? <a href={item.link} target="_blanck" aria-label={item.titulo} rel="noreferrer">{item.titulo}</a>
                        :item.titulo
                    }
                    {item.opciones.length > 0 && <Icon icon={ elemetHover === index ? iconUp : iconDown } />}
                    {
                      item.opciones.length > 0 &&
                        <div className="dropdown-content">
                          {
                            item.subtitulo2 !== undefined &&
                            <div className="title-submenu">
                              <NavLink to={item.subtitulo2.path}>
                                <div style={{ position: "auto" }} 
                                  //className={active === splitPath[2] ? "pathActive" : ""}
                                >
                                  {item.subtitulo2.titulo}
                                </div>{" "}
                                <div className="arrow-menu" style={{ left: index === 0 ? "260px" : "80px" }}></div>
                              </NavLink>
                            </div>
                          }
                          <div className="subtitle-menu">{item.subtitulo}</div>
                          <div className="contain-submenu">
                            {
                                item.opciones.map((submenu, key) => {
                                let path = submenu.path;                                
                                let splitPath = path.split('/');

                                return(
                                  <>         
                                    {
                                      item.id !== 'language' 
                                      ? <NavLink to={submenu.path}>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            marginBottom: "10px",
                                            textAlign: "left",
                                          }}
                                        >
                                          {submenu.icon !== undefined && <img src={submenu.icon} style={{ marginRight: "10px" }} alt="img" />}
                                          <div 
                                            key={key} 
                                            style={{ 
                                              fontWeight: "normal", 
                                            color: active === splitPath[2] ? "#004A88" : "" 
                                            }}
                                          >
                                            {submenu.titulo}
                                          </div>
                                        </div>
                                      </NavLink>
                                      :
                                        <div style={{ fontSize: "12px", marginRight: "10px" }}>
                                          <input
                                            type="radio"
                                            value={submenu.value}
                                            name="gender"
                                            checked={language === submenu.value}
                                            onChange={() => handleLanguage(submenu.value)}
                                          />{" "}
                                          {submenu.titulo}
                                        </div>
                                    }                           
                                    
                                  </>
                                )
                              })
                            }
                           
                           

                          </div>
                        </div>
                      }                   
                  </div>
                  
                </div>
              )
            })
          }
          <div style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center'
          }}>
            <a href="http://wa.me/+525580453683" target={"_blank"} rel="noreferrer" aria-label="whatsapp">
              <div className="icon-w"></div>
            </a>
          </div>
        </div>
      </div>
      {/* {isOpen === true && <PanelHeaderResponsive isOpen={(e) => setIsOpen(false)} open=
      {isOpen}/>} */}

      <PanelHeaderResponsive isOpen={(e) => setIsOpen(false)} open={isOpen}/>
    </>
  );
}

export default PanelHeader;
