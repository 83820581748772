import React, { useContext } from "react";
import { Col, Row } from "reactstrap";
import LanguageContext from "context/context-language";
import "assets/css/wifi.css";

const data = {
    es: [
        {text: "40 Usuarios concurrentes.", icon: require("../assets/img/servicios/puntos_acceso_wifi/usuarios.png")},
        {text: "Conexión libre y sin pedir datos personales.", icon: require("../assets/img/servicios/puntos_acceso_wifi/conexion.png")},
        {text: "Control de navegación con listas negras", icon: require("../assets/img/servicios/puntos_acceso_wifi/navegación.png")},
        {text: <div><span>Cuenta con 50 Mbps a 100 Mbps por un punto de acceso. </span><br/><span style={{color: 'rgb(99, 101, 106)', fontWeight: 'normal'}}>Tasa de transferencia de datos de 5 Mbps de descarga y 3 Mbps de carga o de 3 Mbps de descarga y 1 Mbps de carga por usuario.</span></div>, icon: require("../assets/img/servicios/puntos_acceso_wifi/mb.png")},
        {text: "Alcance de 100 mts con línea de vista por cada punto de acceso.", icon: require("../assets/img/servicios/puntos_acceso_wifi/alcance.png")},
        {text: "Monitoreo remoto 7/24, Call center para reportes del municipio.", icon: require("../assets/img/servicios/puntos_acceso_wifi/monitoreo.png")},
        {text: <div>Servicio siempre disponible en todo el país. <br/> Garantizado.</div>, icon: require("../assets/img/servicios/puntos_acceso_wifi/servicio.png")},
        {text: "Atención y soporte en sitio en 6 horas de 8 am a 8 pm", icon: require("../assets/img/servicios/puntos_acceso_wifi/soporte.png")},
        {text: 'Empresa 100% Mexicana con 20 años de experiencia y Permiso de "Comercializadora" por IFETEL.', icon: require("../assets/img/servicios/puntos_acceso_wifi/empresa.png")}
    ],
    en: [
        {text: "40 Usuarios concurrentes.", icon: require("../assets/img/servicios/puntos_acceso_wifi/usuarios.png")},
        {text: "Conexión libre y sin pedir datos personales.", icon: require("../assets/img/servicios/puntos_acceso_wifi/conexion.png")},
        {text: "Control de navegación con listas negras", icon: require("../assets/img/servicios/puntos_acceso_wifi/navegación.png")},
        {text: <div><span>Cuenta con 50 Mbps a 100 Mbps por un punto de acceso. </span><br/><span style={{color: 'rgb(99, 101, 106)', fontWeight: 'normal'}}>Tasa de transferencia de datos de 5 Mbps de descarga y 3 Mbps de carga o de 3 Mbps de descarga y 1 Mbps de carga por usuario.</span></div>, icon: require("../assets/img/servicios/puntos_acceso_wifi/mb.png")},
        {text: "Alcance de 100 mts con línea de vista por cada punto de acceso.", icon: require("../assets/img/servicios/puntos_acceso_wifi/alcance.png")},
        {text: "Monitoreo remoto 7/24, Call center para reportes del municipio.", icon: require("../assets/img/servicios/puntos_acceso_wifi/monitoreo.png")},
        {text: "Servicio siempre disponible en todo el país." + <br/> +" Garantizado.", icon: require("../assets/img/servicios/puntos_acceso_wifi/servicio.png")},
        {text: "Atención y soporte en sitio en 6 horas de 8 am a 8 pm", icon: require("../assets/img/servicios/puntos_acceso_wifi/soporte.png")},
        {text: 'Empresa 100% Mexicana con 20 años de experiencia y Permiso de "Comercializadora" por IFETEL.', icon: require("../assets/img/servicios/puntos_acceso_wifi/empresa.png")}
    ]
}

function Layout_puntosAccesoWifi(){
    const { language, handleLanguage } = useContext(LanguageContext);  
    return(
        <>
        <div className="card-detalle card-detalleWifi">
            <div className="wifi1">
                <div>
                    {
                        data[language].map(item => {
                            return (
                                <>
                                    <div style={{display: "flex", gap: '10px', height: 'max-content', margin:'20px 0 20px 0'}}>
                                        <div className="iconWifi">
                                            <img src={item.icon} style={{width: '40px'}}/>
                                        </div>
                                        <div className="textWifi">
                                        {item.text}
                                        </div>
                                    </div>
                                </>                                        
                            )
                        })
                    }
                    </div>
                </div>
            <div className="wifi2"                    
            ></div>
        </div>
        </>
    )
}

export default Layout_puntosAccesoWifi;